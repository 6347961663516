<template>
  <div>
    <v-container>
      <v-row class="d-flex justify-center">
        <v-col>
          <v-card class="elevation-16" width="auto">
            <v-stepper v-model="e1" vertical>
              <v-stepper-step color="#30D5C8" :complete="e1 > 1" step="1">
                Search For a Brand
              </v-stepper-step>
              <v-stepper-content step="1">
                <v-card class="mt-1" flat>
                  <v-row no-gutters>
                    <v-col class="col-sm-12 col-md-4 col-lg-4">
                      <v-text-field
                        label="Enter Brand Name"
                        v-validate="'required'"
                        data-vv-name="brand"
                        :error-messages="errors.collect('brand')"
                        filled
                        rounded
                        dense
                        v-model="searchCriteria.text"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-card>

                <v-row no-gutters>
                  <v-btn class="ma-2" color="primary" fab small @click="e1 = 1">
                    <v-icon>mdi-arrow-left</v-icon>
                  </v-btn>
                  <v-btn
                    class="ma-2"
                    color="success"
                    fab
                    small
                    @click="searchBrand()"
                    :disabled="searchCriteria.text == undefined"
                  >
                    <v-icon>mdi-arrow-right</v-icon>
                  </v-btn>
                </v-row>
              </v-stepper-content>
              <v-stepper-step color="#30D5C8" :complete="e1 > 2" step="2">
                Pick a brand
              </v-stepper-step>
              <v-stepper-content step="2">
                <v-card class="mt-1" flat>
                  <v-row no-gutters>
                    <v-col>
                      <v-autocomplete
                        label="Brands"
                        :items="brands"
                        :item-text="
                          (item) =>
                            `${item.brand.brand} - ${item.data[0].subcategory}`
                        "
                        item-value="brand.brandId"
                        v-model="dataToSend.brandId"
                        required
                        chips
                        deletable-chips
                        small-chips
                        dense
                        outlined
                        data-vv-name="brands"
                        :error-messages="errors.collect('brands')"
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-card>
                <v-row no-gutters>
                  <v-btn class="ma-2" color="primary" fab small @click="e1 = 1">
                    <v-icon>mdi-arrow-left</v-icon>
                  </v-btn>
                  <v-btn
                    class="ma-2"
                    color="success"
                    fab
                    small
                    @click="redirectPage()"
                  >
                    <v-icon>mdi-arrow-right</v-icon>
                  </v-btn>
                </v-row>
              </v-stepper-content>
            </v-stepper>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="showMessage">
      <p>The feature is not supported in your country</p>
    </v-container>
  </div>
</template>


<script>
import { ReviewDataService } from "../../services/DataServiceiReview";

const reviewDataService = new ReviewDataService();

export default {
  data() {
    return {
      brands: [],
      dataToSend: [],
      searchCriteria: {},
      e1: 1,
      isValid: false,
      showMessage: false,
      redirectTo: "",
      // country: ''
    };
  },
  mounted() {
    if (this.$route.params.redirect == "giveReview") {
      this.redirectTo = "ireviewInfo";
    } else {
      this.redirectTo = "review";
    }
  },

  methods: {
    searchBrand() {
      this.$setLoader();
      this.brands = [];

      reviewDataService.findBrand(this.searchCriteria).then((r) => {
        this.brands = r.data.brands;
        this.$clearLoader();
        this.e1 = 2;
      });
    },

    redirectPage() {
      let data = {
        brandId: this.dataToSend.brandId,
        userId: this.$store.state.userProfile._id,
        country: this.country,
      };
      this.$router.push({ name: this.redirectTo, params: { data } });
    },
  },
};
</script>
<style scoped>
#bt1 {
  float: right;
}
</style>